import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';
import Icon from 'modules/theme/components/assets/noPhoto/svg/UserAvatar.svg';

interface IUserNoPhoto {
    width: number | string;
}

export const UserNoPhoto: FC<IUserNoPhoto> = ({width}) => {
    return <Svg icon component={Icon} width={width}/>;
};
