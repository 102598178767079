export enum EMethod {
    SQL_CLICK_TO_CALL = 'sql click to call',
    SRL_OPEN_MESSENGER = 'srl open messenger',
    SRL_OPEN_WHATSAPP = 'srl open whatsapp',
    SQL_SEND_EMAIL = 'sql send email',
    SQL_SCHEDULE_VIEWING = 'sql schedule viewing',
    SHOW_3D_VIDEO = 'show 3D video',
    SHOW_MAP = 'show map',
    SHOW_VIDEO = 'show video',
    SRL_SHOW_PHONE_NUMBER = 'srl show phone number',
    ALERT_SUBSCRIPTION_FORM = 'alert subscription form'
}
