import {FC, ReactNode} from 'react';
import {Badge} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {BadgeOrigin} from '@mui/material/Badge/Badge';
import BadgeColoredIcon from 'modules/theme/components/icons/basic/BadgeColored.svg';

interface IBadgeWrapper {
    showBadge: boolean;
    width: number;
    right?: boolean;
    left?: boolean;
    children: ReactNode | ReactNode[];
}

export const BadgeWrapper: FC<IBadgeWrapper> = ({showBadge, children, right, left, width}) => {
    if (right && left) {
        throw Error('You can use only one of right | left');
    }

    const anchorOrigin: BadgeOrigin = {
        vertical: 'bottom',
        horizontal: left ? 'left' : 'right'
    };

    return <Badge
        anchorOrigin={{...anchorOrigin}}
        badgeContent={showBadge && <Svg icon component={BadgeColoredIcon} width={width}/>}
        sx={{
            '& .MuiBadge-anchorOriginBottomLeft': {left: '20%', bottom: '10%'},
            '& .MuiBadge-anchorOriginBottomRight': {right: '20%', bottom: '10%'}
        }}
    >
        {children}
    </Badge>;
};
