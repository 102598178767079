export enum ESubcategory {
    STUDIO_APARTMENT = 'Garsónka',
    DOUBLE_STUDIO_APARTMENT = 'Dvojgarsónka',
    ONE_ROOM_APARTMENT = '1 izbový byt',
    TWO_ROOM_APARTMENT = '2 izbový byt',
    THREE_ROOM_APARTMENT = '3 izbový byt',
    FOUR_ROOM_APARTMENT = '4 izbový byt',
    FIVE_PLUS_ROOM_APARTMENT = '5 a viac izbový byt',
    HOLIDAY_APARTMENT = 'Apartmán',
    LOFT = 'Loft',
    MAISONETTE = 'Mezonet',
    OTHER_TYPE_OF_APARTMENT = 'Iný byt',
    FAMILY_HOUSE = 'Rodinný dom',
    COUNTRY_HOUSE = 'Vidiecky dom',
    FARM_SETTLEMENT = 'Farmárska usadl.',
    MOBILE_HOUSE = 'Mobilný dom',
    HOUSEBOAT = 'Hausbót',
    OTHER_OBJECT_FOR_HOUSING = 'Iný objekt na bývanie',
    CABIN_AND_LOG_CABIN = 'Chata, drevenica, zrub',
    COTTAGE_AND_RECREATION_HOUSE = 'Chalupa, rekreačný domček',
    GARDEN_HUT = 'Záhradná chatka',
    OTHER_RECREATIONAL_OBJECT = 'Iný rekreačný objekt',
    LAND_FOR_FAMILY_HOUSE = 'Pozemok pre rodinné domy',
    RECREATIONAL_LAND = 'Rekreačný pozemok',
    LAND_FOR_HOUSING_CONSTRUCTION = 'Pozemok pre bytovú výstavbu',
    LAND_FOR_CIVIC_AMENITIES = 'Pozemok pre občiansku vybavenosť',
    COMMERCIAL_ZONE = 'Komerčná zóna',
    INDUSTRIAL_ZONE = 'Priemyselná zóna',
    MIXED_ZONE = 'Zmiešaná zóna',
    GARDEN = 'Záhrada',
    ORCHARD = 'Ovocný sad',
    VINEYARD_AND_HOP_GARDEN = 'Vinica, chmelnica',
    MEADOW_AND_PASTURE = 'Lúka, pasienok',
    ARABLE_LAND = 'Orná pôda',
    FOREST_LAND = 'Lesy',
    POND_AND_LAKE = 'Rybník, jazero',
    LAND_FOR_ADVERTISING = 'Pozemok pre reklamu',
    OTHER_TYPE_OF_LAND = 'Iný pozemok',
    OFFICES = 'Kancelárie, administratívne priestory',
    BUSINESS_SPACES = 'Obchodné priestory',
    RESTAURANT_SPACES = 'Reštauračné priestory',
    STORAGE_AREAS = 'Priestor pre sklad',
    SPACE_FOR_PRODUCTION = 'Priestor pre výrobu',
    REPAIR_AREA = 'Opravárenský priestor',
    SPORTS_FACILITIES = 'Športové priestory',
    SPACE_FOR_ADVERTISEMENT = 'Plocha pre reklamu',
    OTHER_TYPE_OF_SPACE = 'Iný priestor',
    APARTMENT_HOUSE = 'Apartmánový dom',
    RENTAL_HOUSE = 'Nájomný dom',
    OFFICE_BUILDING = 'Administratívny objekt',
    COMMERCIAL_OBJECT = 'Objekt pre obchod',
    RESTAURANT = 'Reštaurácia',
    POLYFUNCTIONAL_BUILDING = 'Polyfunkčný objekt',
    WAREHOUSE = 'Skladový objekt',
    MANUFACTURING_FACILITY = 'Výrobný objekt',
    REPAIR_FACILITY = 'Opravárenský objekt',
    OBJECT_FOR_BREEDING_ANIMALS = 'Poľnohospodársky objekt a družstvo',
    HOTEL_AND_PENSION = 'Hotel, penzión, ubytovňa',
    SPA = 'Kúpeľný objekt',
    HISTORICAL_OBJECT = 'Historický objekt',
    COMMERCIAL_FACILITY = 'Prevádzkový areál',
    SPORT_OBJECT = 'Športová hala, závodisko',
    SMALL_POWER_STATION = 'Malá elektráreň',
    GAS_STATION = 'Čerpacia stanica PHM',
    MOBILE_CELLS_AND_STANDS = 'Mobilné bunky a stánky',
    OTHER_TYPE_OF_OBJECT = 'Iný objekt',
    DETACHED_GARAGE = 'Garáž',
    CONSOLIDATED_GROUND = 'Spevnené plochy',
    OTHER_AGRICULTURAL_OBJECT = 'Iný poľnohosp. objekt',
    OTHER_LAND_TO_BUILDING = 'Iný stavebný pozemok',
    OTHER_AGRICULTURAL_LAND = 'Iný poľnohosp. pozemok',

    COMMERCIAL_PLOTS = 'Komerčné pozemky',
    SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS = 'Sady, vinice, chmelnice',
    SECONDARY_AGRICULTURAL_AND_FOREST_LAND = 'Poľnohospodárske a lesné pozemky',
    SECONDARY_GARAGE_AND_PARKING = 'Garáže, parkovanie',
    SECONDARY_OFFICES_AND_ADMINISTRATION = 'Kancelárie a administratíva',
    SECONDARY_RECREATIONAL_LAND = '',

    SECONDARY_THE_SHOP = 'Obchod',
    SECONDARY_A_RESTAURANT = 'Reštaurácia',
    SECONDARY_WAREHOUSES = 'Sklady',
    SECONDARY_PRODUCTION = 'Výroba',
    SECONDARY_ANOTHER_SPACE_AND_OBJECT = 'Iný priestor a objekt',
    SECONDARY_COMMERCIAL_PLOTS = 'Komerčné pozemky',
    SECONDARY_GARDEN = 'Záhrada',
    SECONDARY_HOTEL_GUESTHOUSE = 'Hotel, penzión',
}
