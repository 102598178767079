export enum ECategory {
    REAL_ESTATES = 'Nehnuteľnosti',
    APARTMENTS = 'Byty',
    HOUSES = 'Domy',
    COTTAGES_AND_CABINS = 'Chaty a chalupy',
    LANDS = 'Pozemky',
    SPACES = 'Priestory',
    OBJECTS = 'Objekty',
    SECONDARY_RECREATIONAL_PROPERTIES = 'Rekreačné nehnuteľnosti',
    SECONDARY_SPACES_AND_OBJECTS = 'Priestory a objekty'
}
