import React, { FC } from 'react';
import Link from 'components/link/Link';
import { principlesOfProtection } from 'modules/common/links/principlesOfProtection/principlesOfProtection';

export const LinkToGdpr: FC = () => {
    return (
        <Link
            href={
                principlesOfProtection
            }
            openInNewTab
            sx={{ textDecoration: 'underline' }}
        >
            Informáciou o spracúvaní osobných údajov
        </Link>
    );
};
