import {FC} from 'react';
import {Box} from '@mui/material';
import {UserPhoto} from 'modules/theme/components/assets/userBudges/user/partials/UserPhoto';
import {BadgeWrapper} from 'modules/theme/components/assets/userBudges/user/partials/BadgeWrapper';

interface IUser {
    width: number;
    badge?: boolean;
    badgeRight?: boolean;
    badgeLeft?: boolean;
    photoUrl?: string;
    badgeWidth?: number;
    alt?: string;
    title?: string;
}

export const User: FC<IUser> = ({width, badge, badgeWidth, photoUrl, badgeLeft, badgeRight, alt, title}) => {

    if (!badge && (badgeLeft || badgeRight)) {
        throw Error('You cannot use vertical or horizontal without badge.');
    }

    return <Box width={width}>
        <BadgeWrapper
            left={badgeLeft}
            showBadge={badge}
            right={badgeRight}
            width={badgeWidth || width / 2}
        >
            <Box
                width={width}
                height={width}
                overflow="hidden"
                borderRadius="50%"
                position="relative"
            >
                <UserPhoto photoUrl={photoUrl} width={width} alt={alt} title={title} />
            </Box>
        </BadgeWrapper>
    </Box>;
};
